import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { FORM_FIELD_TYPE } from "../const";
import DroppedItem from "../DroppedItem";
import MobileInputsPreview from "../MobileInputsPreview";

function GroupInfoFields({ title, details = {}, children, useValue, renderAsMobile, employeeInfo, workDetailInfo }) {
    const renderPreview = (temp = {}) => {
        return (
            <MobileInputsPreview
                name={temp.name}
                type={temp.type}
                details={temp.validation || {}}
                description={temp.description}
                data={temp}
                // use the value as a placeholder
                useValue={useValue}
                renderAsMobile={renderAsMobile}
                employeeInfo={employeeInfo}
                workDetailInfo={workDetailInfo}
            />
        );
    };

    const renderValue = useMemo(() => {
        if (children) {
            return children;
        }
        return details.value.map((it, idx) => (
            <DroppedItem key={idx} id={it.id} data={it.data} inline={[FORM_FIELD_TYPE.TOGGLE].includes(it.data.type)}>
                {renderPreview(it.data)}
            </DroppedItem>
        ));
    }, [children, details.value]);

    const classname = useMemo(() => {
        let temp = "group-info-fields ";
        if (details.isColumn) temp += "group-info-fields--column";
        return temp.trim();
    }, [details.isColumn]);

    if (!details?.value || (Array.isArray(details.value) && !details.value.length)) {
        return <></>;
    }

    return (
        <div className={classname}>
            <div className="group-info-fields__inner">
                <div className="group-info-fields__header">
                    <div className="group-info-fields__title">{title || details.title}</div>
                </div>
                <div className="group-info-fields__content">{renderValue}</div>
            </div>
        </div>
    );
}

export default GroupInfoFields;

GroupInfoFields.propTypes = {
    title: PropTypes.any,
    children: PropTypes.any,
    value: PropTypes.array,
    details: PropTypes.object,
    useValue: PropTypes.bool,
    renderAsMobile: PropTypes.bool,
    employeeInfo: PropTypes.object,
    workDetailInfo: PropTypes.object
};
