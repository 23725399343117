import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import RemoveIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import { createInitials, createBackground } from "./helper";
import { TOAST_TYPE, blobToBase64, contrast, createConfirmAlert, createToast, megabytesToBytes } from "../../../utilities/helper";
import Loader from "../Loader";
import { DEFAULT_FILE_LIMIT_SIZE, FILE_MIME_TYPES } from "../../../utilities/const";
import Button from "../Button";
import FileRenderer, { FILE_FETCH_TYPE } from "../FileRenderer";
import MyTooltip from "../Tooltip";

export const AV_TYPE = { MEDIUM: 0x1, LARGE: 0x2 };

const IMAGE_ACCEPTS = Object.values(FILE_MIME_TYPES.IMAGE);

function LetteredAvatar({
    name = "",
    color,
    backgroundColors,
    radius,
    size = 48,
    src,
    small,
    style,
    type,
    isLoading,
    isCompany,
    hasborder,
    editmode,
    onChange,
    fetchConfig,
    forceLogo
}) {
    const fileRef = useRef(null);
    const [loading, setLoading] = useState(isLoading);
    const initials = createInitials(name);
    const styles = createStyles({ color, size, radius, style, name, initials, backgroundColors, isLoading, src });
    const createFetchConfig = (conf) => ({ ...conf, nofetch: conf.nofetch || !conf?.paths?.length || !conf?.filename });
    const newFetchConfig = createFetchConfig(fetchConfig || {});

    useEffect(() => {
        if (!!isLoading !== loading) {
            setLoading(!!isLoading);
        }
    }, [isLoading]);

    const clickUpload = (e) => !e.target?.classList?.contains("remove") && fileRef.current && fileRef.current.click();

    const clearUpload = () => {
        fileRef.current && (fileRef.current.value = "");
        typeof onChange === "function" && onChange(null, "");
    };

    const onFileChange = async (e) => {
        try {
            const files = e.target?.files;
            if (files) {
                const file = e.target.files?.[0];
                if (file) {
                    const mimeType = file.type;
                    const size = file.size;
                    const maxFileSize = megabytesToBytes(DEFAULT_FILE_LIMIT_SIZE);
                    if (size > maxFileSize) {
                        throw new Error(`Invalid file size. Must be less than ${maxFileSize} MB.`);
                    }
                    if (!IMAGE_ACCEPTS.some((ia) => ia === mimeType)) {
                        throw new Error("Invalid file type. Must be a JPG,JPEG or PNG");
                    }
                    setLoading(true);
                    const b64 = await blobToBase64(file);
                    setLoading(false);
                    typeof onChange === "function" && onChange(b64, file);
                } else {
                    loading && setLoading(false);
                }
            }
        } catch (error) {
            createToast(error.message, TOAST_TYPE.ERROR);
            fileRef.current && (fileRef.current.value = "");
        }
    };

    const renderImage = () => {
        if (forceLogo && src) {
            return <img className="responsive-img" src={src} alt="image" width={10} height={10} crossOrigin="anonymous" />;
        }
        return (
            <FileRenderer
                src={src}
                onLoading={(bool) => bool != loading && setLoading(bool)}
                fetchType={isCompany ? FILE_FETCH_TYPE.COMPANY : FILE_FETCH_TYPE.EMPLOYEE}
                onChange={(res) => typeof onChange === "function" && onChange(res.src)}
                emptyRender={initials}
                {...newFetchConfig}
            />
        );
    };

    const main = (
        <div
            className={`tk-lettered-avatar ${contrast(styles.parent.backgroundColor)} ${determineClassName({ type, hasborder })}`.trim()}
            style={styles.parent}
        >
            <div className={`tk-lettered-avatar__content ${small ? "small" : ""}`.trim()} style={styles.content}>
                {!src && loading ? <Loader style={{ color: "white" }} relative centered absolute white /> : renderImage()}
            </div>
        </div>
    );

    if (editmode) {
        return (
            <div className="tk-lettered-avatar__container flex gap-05 center">
                {main}
                <div
                    className="flex gap-05 edit center"
                    style={{ backdropFilter: "blur(.1rem)", width: styles.parent.width, height: styles.parent.height }}
                >
                    <MyTooltip message="Edit Avatar">
                        <Button onClick={clickUpload} className="small-font primary-color" options={{ style: { padding: 0 } }} transparent small mini>
                            <EditIcon style={{ color: "white", width: "1.2rem" }} />
                        </Button>
                    </MyTooltip>
                    {src && (
                        <MyTooltip message="Remove Avatar">
                            <Button
                                className="small-font"
                                onClick={() =>
                                    createConfirmAlert({
                                        title: "Remove Photo",
                                        content: "Are you sure this will remove your photo.",
                                        onConfirm: (onClose) => {
                                            clearUpload();
                                            onClose();
                                        }
                                    })
                                }
                                options={{ style: { padding: 0 } }}
                                transparent
                                small
                                mini
                            >
                                <RemoveIcon style={{ color: "red", width: "1.5rem" }} />
                            </Button>
                        </MyTooltip>
                    )}
                </div>
                <input ref={fileRef} name="avatar" style={{ display: "none" }} type="file" onChange={onFileChange} />
            </div>
        );
    }

    return main;
}

const createStyles = ({ color, size, radius, style, name, initials, backgroundColors, isLoading, src }) => {
    const styles = {
        parent: {
            color,
            width: size,
            height: size,
            lineHeight: `${size}px`,
            borderRadius: `${radius || radius === 0 ? radius : size}px`,
            fontSize: `100%`,
            ...(style || {})
        },
        content: {}
    };
    if (name) {
        styles.parent.backgroundColor = createBackground(name, initials, backgroundColors);
    }
    if (isLoading) {
        styles.parent.backgroundColor = "#d1d1d1";
    }
    if (src) {
        styles.parent.backgroundColor = "transparent";
    }
    return styles;
};

const determineClassName = ({ type, hasborder }) => {
    let classname = "";
    if (AV_TYPE.MEDIUM === type) {
        classname = "medium ";
    }
    if (AV_TYPE.LARGE === type) {
        classname = "large ";
    }
    if (hasborder) {
        classname += "has-border ";
    }
    return classname;
};

LetteredAvatar.propTypes = {
    small: PropTypes.bool,
    isLoading: PropTypes.bool,
    name: PropTypes.string,
    color: PropTypes.string,
    src: PropTypes.string,
    backgroundColors: PropTypes.array,
    radius: PropTypes.number,
    size: PropTypes.number,
    style: PropTypes.object,
    type: PropTypes.oneOf(Object.values(AV_TYPE)),
    editmode: PropTypes.bool,
    isCompany: PropTypes.bool,
    hasborder: PropTypes.bool,
    forceLogo: PropTypes.bool,
    onChange: PropTypes.func,
    fetchConfig: PropTypes.shape({
        paths: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
        filename: PropTypes.string
    })
};

export default LetteredAvatar;
