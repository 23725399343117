import { authSlice } from "./common/slice";
import { subscriptionsSlice } from "./admin/subscriptions/slice";
import { rolesSlice } from "./admin/roles/slice";
import { companiesSlice } from "./admin/companies/slice";
import { superAdminCompanySubscriptionsSlice } from "./admin/companySubscriptions/slice";
import { penaltySlice } from "./admin/penalties/slice";
import { formFieldsSlice } from "./admin/formFields/slice";
import { employeesSlice } from "./company/employees/slice";
import { employeesWorkDetailsSlice } from "./company/employeeWorkDetails/slice";
import { workHistorySlice } from "./company/employeeWorkHistories/slice";
import { siteSlice } from "./company/companySites/slice";
import { departmentSlice } from "./company/departments/slice";
import { designationSlice } from "./company/designations/slice";
import { settingsSlice } from "./company/settings/slice";
import { companyPenaltiesSlice } from "./company/companyPenalties/slice";
import { companyPaymentsSlice } from "./company/companyPayment/slice";
import { companyFormsSlice } from "./company/companyForms/slice";
import { employeeSubmittedFormsSlice } from "./company/employeeSubmittedForms/slice";
import { companyHolidaysSlice } from "./company/companyHolidays/slice";
import { employeeOtherIncomesSlice } from "./company/employeesOtherIncomes/slice";
import { employeeDeductionsSlice } from "./company/employeesDeductions/slice";
import { employeePenaltiesSlice } from "./company/employeesPenalties/slice";
import { employeeSalarySlice } from "./company/employeesSalary/slice";
import { employeesHolidayWorkshiftSlice } from "./company/employeeWorkShift/holiday/slice";
import { employeesRegularWorkshiftSlice } from "./company/employeeWorkShift/regular/slice";
import { companyAnnouncementsSlice } from "./company/companyAnnouncements/slice";
import { checkinLogsSlice } from "./company/employeeCheckinLogs/slice";
import { employeeLeavesSlice } from "./company/employeeLeaves/slice";
import { employeeSettlementsSlice } from "./company/employeeSettlements/slice";

import { authAPI } from "./common/api";
import { superAdminAPI } from "./admin/auth/superAdminAPI";
import { subscriptionsAPI } from "./admin/subscriptions/api";
import { rolesAPI } from "./admin/roles/api";
import { companiesAPI } from "./admin/companies/api";
import { superAdminCompanySubscriptionsAPI } from "./admin/companySubscriptions/api";
import { penaltiesAPI } from "./admin/penalties/api";
import { formFieldsAPI } from "./admin/formFields/api";
import { companySubscriptionsAPI } from "./company/companySubscription/api";
import { companyAPI } from "./company/auth/companyAPI";
import { employeesAPI } from "./company/employees/api";
import { workDetailsAPI } from "./company/employeeWorkDetails/api";
import { workHistoryAPI } from "./company/employeeWorkHistories/api";
import { siteAPI } from "./company/companySites/api";
import { departmentAPI } from "./company/departments/api";
import { designationAPI } from "./company/designations/api";
import { companyPenaltyAPI } from "./company/companyPenalties/api";
import { companyPaymentAPI } from "./company/companyPayment/api";
import { companyFormsAPI } from "./company/companyForms/api";
import { employeeSubmittedFormsAPI } from "./company/employeeSubmittedForms/api";
import { companyHolidaysAPI } from "./company/companyHolidays/api";
import { employeeOtherIncomesAPI } from "./company/employeesOtherIncomes/api";
import { employeeDeductionsAPI } from "./company/employeesDeductions/api";
import { employeePenaltiesAPI } from "./company/employeesPenalties/api";
import { workShiftAPI } from "./company/employeeWorkShift/api";
import { companyAnnouncementsAPI } from "./company/companyAnnouncements/api";
import { checkinLogsAPI } from "./company/employeeCheckinLogs/api";
import { employeeLeavesAPI } from "./company/employeeLeaves/api";
import { employeeSettlementsAPI } from "./company/employeeSettlements/api";

export const slices = {
    authSlice,
    subscriptionsSlice,
    rolesSlice,
    companiesSlice,
    superAdminCompanySubscriptionsSlice,
    penaltySlice,
    formFieldsSlice,
    employeesSlice,
    employeesWorkDetailsSlice,
    workHistorySlice,
    siteSlice,
    departmentSlice,
    designationSlice,
    settingsSlice,
    companyPenaltiesSlice,
    companyPaymentsSlice,
    companyFormsSlice,
    employeeSubmittedFormsSlice,
    companyHolidaysSlice,
    employeeOtherIncomesSlice,
    employeeDeductionsSlice,
    employeePenaltiesSlice,
    employeeSalarySlice,
    employeesHolidayWorkshiftSlice,
    employeesRegularWorkshiftSlice,
    companyAnnouncementsSlice,
    checkinLogsSlice,
    employeeLeavesSlice,
    employeeSettlementsSlice
};

export const apis = {
    authAPI,
    superAdminAPI,
    subscriptionsAPI,
    rolesAPI,
    companiesAPI,
    superAdminCompanySubscriptionsAPI,
    penaltiesAPI,
    formFieldsAPI,
    companySubscriptionsAPI,
    companyAPI,
    employeesAPI,
    workShiftAPI,
    workDetailsAPI,
    workHistoryAPI,
    siteAPI,
    departmentAPI,
    designationAPI,
    companyPenaltyAPI,
    companyPaymentAPI,
    companyFormsAPI,
    employeeSubmittedFormsAPI,
    companyHolidaysAPI,
    employeeOtherIncomesAPI,
    employeeDeductionsAPI,
    employeePenaltiesAPI,
    companyAnnouncementsAPI,
    checkinLogsAPI,
    employeeLeavesAPI,
    employeeSettlementsAPI
};

export default { slices, apis };
