import React from "react";
import PropTypes from "prop-types";
import { createGroup } from "../../../common/utilities/helper";
import { useAppSelector } from "../../../common/hooks/reduxHooks";
import { selectCountry, selectUserSetting } from "../../common/slice";
import WarningColorSwitcher from "../../../common/components/extra/WarningColorSwitcher";
import Input, { INPUT_TYPE } from "../../../common/components/extra/form/Input";
import { EMPLOYEE_FILES } from "../../../common/utilities/const";
import InputEmployeeFile from "./InputEmployeeFile";

function UpdateFilesInputs({ base, form, current, isGettingEmployee, onChange }) {
    const setting = useAppSelector(selectUserSetting);
    const country = useAppSelector(selectCountry);
    const isInUAE = country === "ae";

    const timezone = setting.timezone;
    const validity = current.validity;
    const contractValidity = validity?.contract || {};
    const passportValidity = validity?.passportID || {};
    const residenceValidity = validity?.residenceID || {};
    const visaValidity = validity?.visaID || {};
    const laborCardValidity = validity?.labor_card_number || {};

    const createInputDateExpiry = (obj = {}, label, name, value, required = false) => {
        return (
            <Input
                type={INPUT_TYPE.DATE}
                label={label}
                afterLabel={
                    (obj.isExpired || obj.isWarning) && (
                        <WarningColorSwitcher
                            style={{ width: "1rem" }}
                            tooltip={{
                                message: obj.isWarning ? "Is Expiring" : "Is Expired",
                                className: "flex align-center",
                                style: { marginBottom: "3px" }
                            }}
                            danger={obj.isExpired}
                            warning={obj.isWarning}
                        />
                    )
                }
                name={name}
                onChange={(value) => onChange({ target: { name, value: value || null } })}
                selected={value && new Date(value)}
                isLoading={isGettingEmployee}
                timezone={timezone}
                required={required}
                noPast
            />
        );
    };

    return (
        <>
            {createGroup({
                base,
                title: "Documents",
                body: (
                    <>
                        {createInputDateExpiry(
                            contractValidity,
                            "Contract Expiry Date",
                            "EmployeeContract.end_date",
                            form?.EmployeeContract?.end_date,
                            true
                        )}

                        <InputEmployeeFile
                            keyName={EMPLOYEE_FILES.CONTRACT.key}
                            id={current?.id}
                            style={{ marginTop: ".5rem" }}
                            value={form.uploads[EMPLOYEE_FILES.CONTRACT.key]}
                            label="Attach Contract Copy"
                            name="uploads.contract"
                            isLoading={isGettingEmployee}
                            onChange={(_, file) => onChange({ target: { name: "uploads.contract", value: file } })}
                            required={!form.uploads[EMPLOYEE_FILES.CONTRACT.key]}
                        />
                        <Input
                            type={INPUT_TYPE.TEXT}
                            label="Passport No."
                            name="passportID"
                            onChange={onChange}
                            value={form.passportID || ""}
                            minLength={7}
                            maxLength={10}
                            isLoading={isGettingEmployee}
                            required
                        />

                        {createInputDateExpiry(
                            passportValidity,
                            "Passport ID Exp. Date",
                            "passportID_expiration",
                            form?.passportID_expiration || "",
                            true
                        )}

                        <InputEmployeeFile
                            keyName={EMPLOYEE_FILES.PASSPORT.key}
                            id={current?.id}
                            style={{ marginTop: ".5rem" }}
                            value={form.uploads[EMPLOYEE_FILES.PASSPORT.key]}
                            label="Attach Passport Copy"
                            name="uploads.passport"
                            isLoading={isGettingEmployee}
                            onChange={(_, file) => onChange({ target: { name: "uploads.passport", value: file } })}
                            required={!form.uploads[EMPLOYEE_FILES.PASSPORT.key]}
                        />
                        <Input
                            type={INPUT_TYPE.TEXT}
                            label="Residence ID No."
                            name="residenceID"
                            onChange={onChange}
                            value={form.residenceID || ""}
                            minLength={isInUAE ? 15 : 11}
                            maxLength={isInUAE ? 15 : 11}
                            isLoading={isGettingEmployee}
                            required={!!(form?.residenceID_expiration || form.uploads[EMPLOYEE_FILES.RESIDENCE.key])}
                        />

                        {createInputDateExpiry(
                            residenceValidity,
                            "Residence ID Exp. Date",
                            "residenceID_expiration",
                            form?.residenceID_expiration || "",
                            !!(form?.residenceID || form.uploads[EMPLOYEE_FILES.RESIDENCE.key])
                        )}

                        <InputEmployeeFile
                            keyName={EMPLOYEE_FILES.RESIDENCE.key}
                            id={current?.id}
                            label="Attach ID Copy"
                            name="uploads.residence"
                            style={{ marginTop: ".5rem" }}
                            value={form.uploads[EMPLOYEE_FILES.RESIDENCE.key]}
                            isLoading={isGettingEmployee}
                            onChange={(_, file) => onChange({ target: { name: "uploads.residence", value: file } })}
                            required={!form.uploads[EMPLOYEE_FILES.RESIDENCE.key] && !!(form?.residenceID || form?.residenceID_expiration)}
                            alwaysEnableRemove
                        />

                        {isInUAE && (
                            <div className="flex column gap-05">
                                <Input
                                    type={INPUT_TYPE.TEXT}
                                    label="Labor Card No."
                                    name="labor_card_number"
                                    onChange={onChange}
                                    value={form.labor_card_number || ""}
                                    minLength={8}
                                    maxLength={10}
                                    isLoading={isGettingEmployee}
                                    required={!!(form?.labor_card_expiration || form.uploads[EMPLOYEE_FILES.LABOR_CARD.key])}
                                />

                                {createInputDateExpiry(
                                    laborCardValidity,
                                    "Labor Card Exp. Date",
                                    "labor_card_expiration",
                                    form?.labor_card_expiration || "",
                                    !!(form?.labor_card_number || form.uploads[EMPLOYEE_FILES.LABOR_CARD.key])
                                )}

                                <InputEmployeeFile
                                    keyName={EMPLOYEE_FILES.LABOR_CARD.key}
                                    id={current?.id}
                                    style={{ marginTop: ".5rem" }}
                                    value={form.uploads[EMPLOYEE_FILES.LABOR_CARD.key]}
                                    label="Attach Labor Card Copy"
                                    name="uploads.labor-card"
                                    isLoading={isGettingEmployee}
                                    onChange={(_, file) => onChange({ target: { name: "uploads.labor-card", value: file } })}
                                    required={
                                        !form.uploads[EMPLOYEE_FILES.LABOR_CARD.key] && !!(form?.labor_card_number || form?.labor_card_expiration)
                                    }
                                    alwaysEnableRemove
                                />
                            </div>
                        )}
                        <Input
                            type={INPUT_TYPE.TEXT}
                            label="Visa No."
                            name="visaID"
                            onChange={onChange}
                            value={form.visaID || ""}
                            minLength={isInUAE ? 7 : 10}
                            maxLength={isInUAE ? 9 : 12}
                            isLoading={isGettingEmployee}
                            required={!!(form?.visaID_expiration || form.uploads[EMPLOYEE_FILES.VISA.key])}
                        />

                        {createInputDateExpiry(
                            visaValidity,
                            "Visa ID Exp. Date",
                            "visaID_expiration",
                            form?.visaID_expiration || "",
                            !!(form?.visaID || form.uploads[EMPLOYEE_FILES.VISA.key])
                        )}

                        <InputEmployeeFile
                            keyName={EMPLOYEE_FILES.VISA.key}
                            id={current?.id}
                            style={{ marginTop: ".5rem" }}
                            value={form.uploads[EMPLOYEE_FILES.VISA.key]}
                            label="Attach Visa Copy"
                            name="uploads.visa"
                            isLoading={isGettingEmployee}
                            onChange={(_, file) => onChange({ target: { name: "uploads.visa", value: file } })}
                            required={!form.uploads[EMPLOYEE_FILES.VISA.key] && !!(form?.visaID || form?.visaID_expiration)}
                            alwaysEnableRemove
                        />
                    </>
                )
            })}
        </>
    );
}

export default UpdateFilesInputs;

UpdateFilesInputs.propTypes = {
    base: PropTypes.string,
    form: PropTypes.object,
    current: PropTypes.object,
    isGettingEmployee: PropTypes.bool,
    onChange: PropTypes.func
};
