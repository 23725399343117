import React from "react";
import PropTypes from "prop-types";

import Input, { INPUT_TYPE } from "../../../common/components/extra/form/Input";
import Divider from "../../../common/components/extra/Divider";
import Card from "../../../common/components/extra/Card";
import Select from "../../../common/components/extra/select/Select";
import {
    camelToSnake,
    createMiniTootlip,
    formattedJoiErrorMessage,
    setPreventDefault,
    snakeToCamel,
    toOrdinal,
    toReadableSelectOptions
} from "../../../common/utilities/helper";
import Controls from "./Controls";
import { useManageGeneralState } from "./hooks";
import { createSalaryOptions } from "./helper";
import {
    SALARY_FIELDS,
    SUPPORTED_ELIGIBILITY,
    SUPPORTED_ELIGIBILITY_INFO,
    SUPPORTED_ENTITLEMENT_VALUES,
    SUPPORTED_ENTITLEMENT_YEARS_INFO
} from "./const";
import SectionCollapseError from "../../../common/components/extra/section/SectionCollapseError";
import Text from "../../../common/components/extra/typography/Text";
import SectionCollapseInfo from "../../../common/components/extra/section/SectionCollapseInfo";

const LABOR_LAW = process.env.REACT_APP_LABOR_LAW;

function SalarySettings({ setParentLoading, onLoading, readOnly }) {
    const { eligibilitiesOpt, entitlementOpt, daysOpt, monthlySalaryDaysOpt, timesheetTypeOpt, settlementDailyRateByOpt } = createSalaryOptions();

    const [
        setting,
        isLoading,
        { countrySettings, hasChanges, hasError, isEditing, handleCancel, handleSubmit, toggleEdit, handleChange, isCutOff, error, timesheetType }
    ] = useManageGeneralState((bool) => {
        setParentLoading?.(bool);
        onLoading?.(bool);
    });

    const salary = setting.salary;
    const settlementSetting = countrySettings?.settlement;
    const baseSalaryOpt = toReadableSelectOptions(settlementSetting?.base_salary || {});
    const eligibilitySetting = countrySettings?.eligibilities || {};
    const leaveSetting = countrySettings?.leave || {};
    const gratuitySetting = countrySettings?.gratuity || {};
    const leaveEntitlementSetting = leaveSetting?.entitlement || {};
    const gratuityEntitlementSetting = gratuitySetting?.entitlement || {};

    const inputPropsValue = (value) => {
        if (isEditing && !readOnly) {
            return { value };
        } else {
            return {
                renderValue: value === true ? "Yes" : value === false ? "No" : value,
                readOnly: true
            };
        }
    };

    const renderInput = ({ name, label, value, onChange, options, afterExtra, isToggle, ...rest } = {}) => {
        if (isEditing && !readOnly) {
            if (isToggle) {
                return (
                    <Input
                        type={INPUT_TYPE.TOGGLE}
                        name={name}
                        label={label}
                        onChange={(e) =>
                            onChange({
                                target: {
                                    value: e.target.checked,
                                    name: e.target.name
                                }
                            })
                        }
                        value={value}
                        checked={value}
                        {...rest}
                    />
                );
            }

            return (
                <Select
                    options={options}
                    onChange={(val) =>
                        onChange({
                            target: { name, value: val.value }
                        })
                    }
                    value={value}
                    name={name}
                    label={label}
                    placeholder=""
                    style={{ flex: "30%" }}
                    isClearable={false}
                    menuPortalTarget={document.body}
                    isOutlined
                    disabledOutline
                    {...rest}
                />
            );
        }
        return (
            <Input
                type={INPUT_TYPE.TEXT}
                name={name}
                label={label}
                onChange={onChange}
                afterExtra={afterExtra}
                tooltip={rest.tooltip}
                {...inputPropsValue(typeof value === "object" && "label" in value ? value.label : value)}
            />
        );
    };

    return (
        <Card className="salary-settings box-shadow-mini">
            {hasError && (
                <SectionCollapseError show alwaysOpen>
                    {formattedJoiErrorMessage({ error })}
                </SectionCollapseError>
            )}
            <form className="flex column" style={{ gap: "2rem" }} onSubmit={setPreventDefault(handleSubmit)}>
                <div className="flex column">
                    <Divider title={`${!readOnly ? "Manage" : "View"} Salary Settings`} />
                    <p className="fade small-font">
                        These settings govern the calculation of employee salaries, ensuring accuracy and fairness in compensation. Customize
                        parameters to align with your {"organization's"} policies and standards, enabling precise and consistent payroll management.
                    </p>
                    <div className="flex column" style={{ gap: "2rem" }}>
                        <div className="flex column gap-05">
                            <span className="bold" style={{ marginLeft: ".5rem" }}>
                                Monthly Timesheet
                            </span>
                            <div className="group flex column" style={{ marginLeft: "1rem" }}>
                                {renderInput({
                                    name: "timesheet",
                                    label: "Type",
                                    onChange: handleChange,
                                    value: timesheetTypeOpt.find((opt) => timesheetType == opt.value) || "",
                                    options: timesheetTypeOpt,
                                    required: true,
                                    autoFocus: true
                                })}
                                {isCutOff &&
                                    renderInput({
                                        name: "salary." + SALARY_FIELDS.TIMESHEET_END_DAY,
                                        label: "Cutoff Day",
                                        onChange: handleChange,
                                        value: daysOpt.find((opt) => opt.value == salary?.[SALARY_FIELDS.TIMESHEET_END_DAY]) || "",
                                        options: daysOpt,
                                        required: true,
                                        menuPlacement: "top"
                                    })}

                                {salary?.[SALARY_FIELDS.TIMESHEET_START_DAY] && (
                                    <Text useSubTextStyle>
                                        {isCutOff ? (
                                            <span style={{ marginLeft: ".5rem", marginTop: ".5rem" }}>
                                                The cutoff period spans from the{" "}
                                                <span className="bold primary-color">{toOrdinal(salary[SALARY_FIELDS.TIMESHEET_START_DAY])}</span>
                                                &nbsp;to the{" "}
                                                <span className="bold primary-color">{toOrdinal(salary[SALARY_FIELDS.TIMESHEET_END_DAY])}</span> day
                                                of the respective months.
                                            </span>
                                        ) : (
                                            <span style={{ marginLeft: ".5rem", marginTop: ".5rem" }}>
                                                Starts on the <span className="bold primary-color">{toOrdinal(1)}</span> day of the month and
                                                concludes on the <span className="bold primary-color">Last Day of the month</span>.
                                            </span>
                                        )}
                                    </Text>
                                )}
                            </div>
                            <div className="flex column" style={{ marginLeft: "1rem" }}>
                                {renderInput({
                                    name: "salary." + SALARY_FIELDS.MONTHLY_SALARY_DAYS,
                                    label: "Monthly Salary Days",
                                    onChange: handleChange,
                                    value: monthlySalaryDaysOpt.find((msd) => msd.value === salary?.[SALARY_FIELDS.MONTHLY_SALARY_DAYS]) || "",
                                    options: monthlySalaryDaysOpt,
                                    required: true
                                })}
                                <Text useSubTextStyle>
                                    <span style={{ marginLeft: ".5rem", marginTop: ".5rem" }}>
                                        Value will be used for OT and Salary Per Day computations.
                                    </span>
                                </Text>
                            </div>
                        </div>
                        <SectionCollapseInfo title="Important Notice" show alwaysOpen>
                            <span>{"The following values must comply with your country's labor laws. Please review them"}</span>&nbsp;
                            <a href={LABOR_LAW} target="_blank" rel="noopener noreferrer">
                                here.
                            </a>
                        </SectionCollapseInfo>
                        {!isEditing && (
                            <div className="flex column gap-05">
                                <span className="bold" style={{ marginLeft: ".5rem" }}>
                                    OT Rates
                                </span>
                                <div className="group flex column" style={{ marginLeft: "1rem" }}>
                                    <Input
                                        type={INPUT_TYPE.NUMBER}
                                        name={"salary." + SALARY_FIELDS.REGULAR_OT_RATE}
                                        label="Regular OT Rate"
                                        onChange={handleChange}
                                        tooltip="Actual value based on the current country Law. Cannot be Changed."
                                        {...inputPropsValue(salary?.[SALARY_FIELDS.REGULAR_OT_RATE])}
                                    />
                                    <Input
                                        type={INPUT_TYPE.NUMBER}
                                        name={"salary." + SALARY_FIELDS.HOLIDAY_OT_RATE}
                                        label="Holiday OT Rate"
                                        onChange={handleChange}
                                        tooltip="Actual value based on the current country Law. Cannot be Changed."
                                        {...inputPropsValue(salary?.[SALARY_FIELDS.HOLIDAY_OT_RATE])}
                                    />
                                </div>
                            </div>
                        )}
                        <div className="flex column gap-05">
                            <span className="bold" style={{ marginLeft: ".5rem" }}>
                                Leave Entitlement
                            </span>
                            <div className="group flex column" style={{ marginLeft: "1rem" }}>
                                {Object.keys(salary?.[SALARY_FIELDS.LEAVE]?.entitlement || {}).map((name, idx) => {
                                    const options = entitlementOpt({
                                        min: leaveEntitlementSetting?.[snakeToCamel(name)]?.min || 21,
                                        max: leaveEntitlementSetting?.[snakeToCamel(name)]?.max || 31
                                    }).filter((option) => SUPPORTED_ENTITLEMENT_VALUES.includes(option.value));
                                    return (
                                        <React.Fragment key={idx}>
                                            {renderInput({
                                                name: "salary.leave.entitlement." + name,
                                                label: SUPPORTED_ENTITLEMENT_YEARS_INFO?.[name]?.label,
                                                onChange: handleChange,
                                                value: options.find((opt) => opt.value == salary.leave?.entitlement?.[name]) || "",
                                                options,
                                                required: true,
                                                menuPlacement: "top"
                                            })}
                                        </React.Fragment>
                                    );
                                })}
                            </div>
                        </div>
                        <div className="flex column gap-05">
                            <span className="bold" style={{ marginLeft: ".5rem" }}>
                                Gratuity Entitlement
                            </span>
                            <div className="group flex column" style={{ marginLeft: "1rem" }}>
                                {Object.keys(salary?.[SALARY_FIELDS.GRATUITY]?.entitlement).map((name, idx) => {
                                    const options = entitlementOpt({
                                        min: gratuityEntitlementSetting?.[snakeToCamel(name)]?.min || 21,
                                        max: gratuityEntitlementSetting?.[snakeToCamel(name)]?.max || 31
                                    }).filter((option) => SUPPORTED_ENTITLEMENT_VALUES.includes(option.value));
                                    return (
                                        <React.Fragment key={idx}>
                                            {renderInput({
                                                name: `salary.${SALARY_FIELDS.GRATUITY}.entitlement.${name}`,
                                                label: SUPPORTED_ENTITLEMENT_YEARS_INFO?.[name]?.label,
                                                onChange: handleChange,
                                                value:
                                                    options.find((opt) => opt.value == salary?.[SALARY_FIELDS.GRATUITY]?.entitlement?.[name]) || "",
                                                options,
                                                required: true,
                                                menuPlacement: "top"
                                            })}
                                        </React.Fragment>
                                    );
                                })}
                            </div>
                        </div>
                        <div className="flex column gap-05">
                            <span className="bold" style={{ marginLeft: ".5rem" }}>
                                Settlement
                            </span>
                            <div className="group flex column" style={{ marginLeft: "1rem" }}>
                                {renderInput({
                                    name: `salary.${SALARY_FIELDS.SETTLEMENT}.base_salary`,
                                    label: "Base Salary",
                                    onChange: handleChange,
                                    value: baseSalaryOpt.find((msd) => msd.value === salary?.[SALARY_FIELDS.SETTLEMENT]?.base_salary) || "",
                                    options: baseSalaryOpt,
                                    tooltip: createMiniTootlip(
                                        "The type of salary to use as the BASE SALARY in the settlement calculation. You can select either the Basic Salary or the Full Salary, which may include allowances or other compensation."
                                    ),
                                    required: true
                                })}
                                {renderInput({
                                    name: `salary.${SALARY_FIELDS.SETTLEMENT}.daily_rate_by`,
                                    label: "Daily Rate By",
                                    onChange: handleChange,
                                    value:
                                        settlementDailyRateByOpt.find((msd) => msd.value === salary?.[SALARY_FIELDS.SETTLEMENT]?.daily_rate_by) || "",
                                    options: settlementDailyRateByOpt,
                                    tooltip: createMiniTootlip(
                                        "The method for calculating the DAILY SALARY RATE when creating a settlement. Choose between a fixed (30) days or a pro-rata calculation based on the days in a year (365.25 days)"
                                    ),
                                    required: true
                                })}
                                {renderInput({
                                    isToggle: true,
                                    name: `salary.${SALARY_FIELDS.SETTLEMENT}.includeUnpaidLeaveDays`,
                                    label: "Include Unpaid Leaves",
                                    onChange: handleChange,
                                    value: salary?.[SALARY_FIELDS.SETTLEMENT]?.includeUnpaidLeaveDays,
                                    tooltip: createMiniTootlip(
                                        "When enabled, UNPAID leave days are counted as part of the Total Service Days, which may affect the calculation of leave and gratuity entitlements and settlements based on the length of service."
                                    ),
                                    required: false
                                })}
                            </div>
                        </div>
                        <div className="flex column gap-05">
                            <span className="bold" style={{ marginLeft: ".5rem" }}>
                                Eligibilities
                            </span>
                            <div className="group flex column" style={{ marginLeft: "1rem" }}>
                                {Object.keys(salary?.eligibilities || {}).map((name, idx) => {
                                    const options = eligibilitiesOpt({
                                        min: eligibilitySetting?.[camelToSnake(name)]?.min || 3,
                                        max: eligibilitySetting?.[camelToSnake(name)]?.max || 12
                                    });
                                    if (!Object.values(SUPPORTED_ELIGIBILITY).includes(name)) {
                                        return <></>;
                                    }
                                    return (
                                        <React.Fragment key={idx}>
                                            {renderInput({
                                                name: "salary.eligibilities." + name,
                                                label: SUPPORTED_ELIGIBILITY_INFO?.[name]?.label,
                                                onChange: handleChange,
                                                value: options.find((opt) => opt.value == salary?.eligibilities?.[name]) || "",
                                                options,
                                                required: true,
                                                menuPlacement: "top"
                                            })}
                                        </React.Fragment>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                </div>
                {!readOnly && (
                    <Controls
                        onCancel={handleCancel}
                        onEdit={toggleEdit}
                        isLoading={isLoading}
                        isEditing={isEditing}
                        disableSave={isLoading || !hasChanges}
                    />
                )}
            </form>
        </Card>
    );
}

export default SalarySettings;

SalarySettings.propTypes = {
    loading: PropTypes.bool,
    readOnly: PropTypes.bool,
    setParentLoading: PropTypes.func,
    onLoading: PropTypes.func
};
