import React, { useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import PropTypes from "prop-types";

const STORAGE_KEY = "active-tab";

function Tabs({ items = [], className, styles: tabStyles, onChange, disableNavigate, hasBackground, useStorage, isCapsule, isV2Rendering }) {
    const styles = { parent: {}, head: {}, body: {}, table: {}, ...(tabStyles || {}) };

    const location = useLocation();
    const hash = (location.hash || "").replace("#", "");
    const activeStorageTab = localStorage.getItem(STORAGE_KEY);

    const getInitialActiveTab = () => {
        const fallback = items?.[0]?.id;
        if (useStorage) {
            const storedActive = localStorage.getItem(STORAGE_KEY);
            if (storedActive && items.find((item) => item.id === storedActive)) {
                return storedActive || fallback;
            }
        } else if (hash) {
            return hash;
        }
        return fallback;
    };

    const [active, setActive] = useState(getInitialActiveTab);

    const activeIdx = useMemo(() => {
        let temp = "";
        if (disableNavigate) {
            temp = active;
        } else if (useStorage) {
            temp = localStorage.getItem(STORAGE_KEY);
        } else {
            temp = hash;
        }
        const idx = items.findIndex((item) => item.id === temp);
        return idx < 0 ? 0 : idx;
    }, [disableNavigate, active, activeStorageTab, hash]);

    const activeItem = useMemo(() => items[activeIdx], [activeIdx]);

    useEffect(() => {
        if (!disableNavigate && ((!useStorage && !hash) || (useStorage && !activeStorageTab)) && items.length) {
            updateUriHash(items[0].id);
        }
        if (items[activeIdx]) {
            typeof onChange === "function" && onChange(items[activeIdx]);
        }
    }, []);

    const updateUriHash = (newhash) => {
        if (disableNavigate) {
            if (active != newhash) {
                setActive(newhash);
            }
        } else if (useStorage) {
            localStorage.setItem(STORAGE_KEY, newhash);
            setActive(newhash);
        } else {
            if (newhash !== hash) {
                window.location.hash = newhash;
            }
        }
    };

    const handleTabChange = (item) => {
        updateUriHash(item.id);
        typeof onChange === "function" && onChange(item);
    };

    const createActiveClass = (it) => {
        return activeItem.id == it.id ? "active" : "";
    };

    return (
        <div className={`tk-tabs ${isCapsule ? "tab-capsule" : ""} ${className || ""}`.trim()} style={styles.parent}>
            <div className="tk-tabs__header" style={styles.head}>
                {(items || []).map((item, idx) => {
                    return (
                        <div
                            key={idx}
                            id={item.id}
                            className={`tk-tabs__item head ${createActiveClass(item)}`.trim()}
                            onClick={() => activeItem && activeItem.id !== item.id && handleTabChange(item)}
                            style={item.style || {}}
                        >
                            <span className="text-ellipsis">{item.label}</span>
                        </div>
                    );
                })}
            </div>
            <div className={"tk-tabs__body w100" + (hasBackground ? " has-bg" : "")} style={styles.body}>
                <div className="tk-tabs__item content" style={styles.content}>
                    {activeItem && (isV2Rendering ? <activeItem.component {...activeItem.props} /> : activeItem?.component)}
                </div>
            </div>
        </div>
    );
}

export default Tabs;

Tabs.propTypes = {
    className: PropTypes.string,
    onChange: PropTypes.func,
    disableNavigate: PropTypes.bool,
    items: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string,
            label: PropTypes.any,
            component: PropTypes.any
        })
    ),
    styles: PropTypes.shape({
        parent: PropTypes.object,
        head: PropTypes.object,
        body: PropTypes.object,
        content: PropTypes.object
    }),
    hasBackground: PropTypes.bool,
    useStorage: PropTypes.bool,
    isCapsule: PropTypes.bool,
    isV2Rendering: PropTypes.bool
};
