import React, { useMemo, useState } from "react";
import PropTypes from "prop-types";
import cloneDeep from "lodash/cloneDeep";
import { SUBMITTED_FORM_STATUS } from "./const";
import { FORM_FIELD_TYPE } from "../companyForms/const";
import Empty from "../../../common/components/extra/Empty";
import MobileInputsPreview from "../companyForms/MobileInputsPreview";
import ViewFileModal from "../employees/ViewFileModal";

function DisplayFormData({ status, formData, isLoading, targetEmployee, targetWorkDetail, sentTo, isProbYearly }) {
    const [preview, setPreview] = useState(null);

    const isReceived = status == SUBMITTED_FORM_STATUS.RECEIVED;

    const fields = useMemo(() => {
        /**
         * @description
         * We need to convert the field data structure to company form structure since thats the way we render our fields
         */
        const normalizeField = (field = {}) => {
            // if no validation field that means it either not filled up yet or its a submitted form data structure
            if (!field?.data?.validation) {
                return {
                    id: field.id,
                    data: {
                        ...field,
                        validation: field
                    }
                };
            } else {
                return field;
            }
        };

        return cloneDeep(formData?.fields || []).map((field) => {
            if ((field?.data?.type || field?.type) === FORM_FIELD_TYPE.GROUP) {
                const value = field?.data?.validation?.value || field.value;
                const isValidArray = value && Array.isArray(value);
                return {
                    id: field.id,
                    data: {
                        ...(field.data || field),
                        validation: {
                            ...(field?.data?.validation || field),
                            value:
                                (isValidArray &&
                                    (field?.data?.validation?.value || field.value).map((groupChildField) => normalizeField(groupChildField))) ||
                                []
                        }
                    }
                };
            }
            return normalizeField(field);
        });
    }, [formData?.fields]);

    const render = useMemo(() => {
        let display = (
            <div className="flex column gap-03">
                {fields.map(({ data }, idx) => {
                    return (
                        <MobileInputsPreview
                            key={idx}
                            name={data.name}
                            type={data.type}
                            details={data.validation || {}}
                            description={data.description}
                            data={data}
                            employeeInfo={targetEmployee}
                            workDetailInfo={targetWorkDetail}
                            onViewFile={(config) =>
                                setPreview({
                                    id: sentTo.id,
                                    filename: config.filename,
                                    type: config.type
                                })
                            }
                            isProbYearly={isProbYearly}
                            useValue
                            renderAsMobile
                        />
                    );
                })}
            </div>
        );

        if (isReceived) {
            display = <Empty style={{ margin: "auto" }} message="Waiting for Reviewer" isLoading={isLoading} />;
        }

        return display;
    }, [formData, status]);

    return (
        <div className="mobile-form-display flex column">
            {render}
            {!!preview && (
                <ViewFileModal
                    open={!!preview}
                    onClose={() => setPreview(null)}
                    type={preview.type}
                    data={{
                        id: preview.id,
                        filename: preview.filename
                    }}
                />
            )}
        </div>
    );
}

export default DisplayFormData;

DisplayFormData.propTypes = {
    targetEmployee: PropTypes.object,
    targetWorkDetail: PropTypes.object,
    status: PropTypes.oneOf(Object.values(SUBMITTED_FORM_STATUS)),
    formData: PropTypes.shape({
        fields: PropTypes.array
    }),
    isLoading: PropTypes.bool,
    isProbYearly: PropTypes.bool,
    sentTo: PropTypes.object
};
