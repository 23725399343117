import React, { useState } from "react";
import ReactDOM from "react-dom";
import InfoIcon from "@mui/icons-material/Info";
import { Tooltip as ReactTooltip } from "react-tooltip";
import PropTypes from "prop-types";
import { v4 as uuidv4 } from "uuid";
import { createMiniTootlip } from "../../utilities/helper";

const domNode = document.createElement("div");
document.body.appendChild(domNode);

function BodyPortal({ children }) {
    return ReactDOM.createPortal(children, domNode);
}

export const PLACES = {
    TOP: "top",
    TOP_START: "top-start",
    TOP_END: "top-end",
    RIGHT: "right",
    RIGHT_START: "right-start",
    RIGHT_END: "right-end",
    BOTTOM: "bottom",
    BOTTOM_START: "bottom-start",
    BOTTOM_END: "bottom-end",
    LEFT: "left",
    LEFT_START: "left-start",
    LEFT_END: "left-end"
};

function MyTooltip({ message, children, className, style, element, onClick, position = PLACES.TOP }) {
    const [object] = useState({ id: uuidv4().split("-").shift() });
    const WrapperElement = element || "div";
    const customid = "my" + object.id;

    if (!message) {
        return (
            <WrapperElement data-tooltip-id={customid} className={("tk-tooltip " + (className || "")).trim()} style={style || {}} onClick={onClick}>
                {children}
            </WrapperElement>
        );
    }
    return (
        <>
            <WrapperElement data-tooltip-id={customid} className={("tk-tooltip " + (className || "")).trim()} style={style || {}} onClick={onClick}>
                {children}
            </WrapperElement>
            <BodyPortal>
                <ReactTooltip id={customid} place={position} style={{ zIndex: 999999 }} opacity={1}>
                    {createMiniTootlip(message)}
                </ReactTooltip>
            </BodyPortal>
        </>
    );
}

function Tooltip({ isIcon, children, ...props }) {
    if (isIcon)
        return (
            <div className={`${props.className || ""} flex align-center gap-05 h100`.trim()} style={{ ...(props.style || {}) }}>
                {children}
                {props.message && (
                    <MyTooltip {...props} style={{}} className="flex align-center" element="div">
                        <InfoIcon className="info-icon hover-svg" style={{ width: "1rem" }} />
                    </MyTooltip>
                )}
            </div>
        );
    return <MyTooltip {...props}>{children}</MyTooltip>;
}

const TooltipProps = {
    children: PropTypes.any,
    message: PropTypes.any,
    position: PropTypes.oneOf(Object.values(PLACES)),
    className: PropTypes.string,
    style: PropTypes.object,
    element: PropTypes.string,
    onClick: PropTypes.func,
    isIcon: PropTypes.bool
};

Tooltip.propTypes = TooltipProps;
MyTooltip.propTypes = TooltipProps;

export default Tooltip;
