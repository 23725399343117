import React, { useEffect, useState } from "react";
import isEqual from "lodash/isEqual";
import cloneDeep from "lodash/cloneDeep";
import isNull from "lodash/isNull";
import { TOAST_TYPE, createPromiseToast, createToast, sanitizeWords, toFormFields } from "../../../common/utilities/helper";
import { DEFAULT_SIZE, LOAD_MORE_OFFSET, defaultConfig } from "./slice";
import { FILTER_ALL_VALUE, SORT_ORDER } from "../../../common/utilities/const";
import { FIELDS, ROUTE_TYPE } from "./const";
import {
    useRemoveCompanyPenaltyMutation,
    useLoadAllCompanyPenaltyLazyMutation,
    useLoadAllLawPenaltiesLazyMutation,
    useSetCompanyPenaltiesByPenaltyIdsMutation,
    useGetPenaltyDetailsByCompanyMutation,
    useValidateCompanyPenaltyFieldsMutation,
    useGetCompanyPenaltyMutation,
    useUpdateCompanyPenaltyMutation,
    useCreateCompanyPenaltyMutation
} from "./api";
import Tooltip from "../../../common/components/extra/Tooltip";

export const INITIAL_UPDATE_FORM = toFormFields(FIELDS, "defaultValue");

export const useFetchPenalty = ({ id, onMount, isByLaw } = {}, newData) => {
    const [data, setData] = useState(null);
    const [isLoading, setLoading] = useState(true);

    let [getDetails] = isByLaw ? useGetPenaltyDetailsByCompanyMutation() : useGetCompanyPenaltyMutation();

    const fetch = async (fetchId) => {
        if (!fetchId) return;
        const res = await getDetails({ extraPath: fetchId });
        if (res.error) {
            createToast("Failed to fetch penalties. Please try again later or contact support", TOAST_TYPE.ERROR);
        }
        if (res.data) {
            const newres = { ...res.data.data, ...(newData || {}) };
            setData(newres);
            typeof onMount === "function" && onMount(newres);
        }
        setLoading(false);
        return res;
    };

    useEffect(() => {
        fetch(id);
    }, []);

    return [data || {}, isLoading];
};

export const useLazyCompanyPenalties = (type, newConfig = {}) => {
    const [isFetching, setIsFetching] = useState(true);

    const [object, setObject] = useState({
        companyPenalties: [],
        sort: {
            sortBy: defaultConfig.sortBy,
            order: defaultConfig.order
        },
        totalCount: 0,
        cursor: "",
        search: "",
        selected: null
    });

    const [load] = useLoadAllCompanyPenaltyLazyMutation();

    const hasMore = object.totalCount > object.companyPenalties?.length || 0;

    const updateObject = (newObject = {}) => setObject({ ...object, ...newObject });

    const fetch = async ({ sort, ...config } = {}, isReset) => {
        if (!isFetching) {
            setIsFetching(true);
        }
        if (!sort) {
            sort = object.sort;
        }
        if (isReset) {
            config.cursor = "";
        }
        try {
            const response = await load({
                extraPath: !type ? ROUTE_TYPE.CUSTOM : type,
                body: {
                    pageSize: DEFAULT_SIZE,
                    more: isReset ? DEFAULT_SIZE : LOAD_MORE_OFFSET,
                    ...object.sort,
                    ...sort,
                    ...(config || {})
                }
            });
            if (response.error) {
                throw new Error(response.error?.data?.message);
            }
            if (response.data && response.data.data) {
                const resdata = response.data.data;
                const temp = {
                    companyPenalties: isReset ? resdata.data : object.companyPenalties.concat(resdata.data),
                    cursor: resdata.cursor,
                    totalCount: resdata.totalCount
                };
                sort && (temp.sort = sort);
                updateObject(temp);
            }
        } catch (error) {
            createToast(error.message || "Something went wrong with the server. Please contact support.", TOAST_TYPE.ERROR);
            sort && updateObject({ sort, companyPenalties: [], totalCount: 0 });
        } finally {
            setIsFetching(false);
        }
    };

    const loadMore = () => {
        hasMore && fetch({ cursor: object.cursor });
    };

    useEffect(() => {
        fetch(newConfig);
    }, []);

    const onSearch = (newInput) => {
        fetch({ search: newInput.trim().toLowerCase() }, true);
    };

    const onSort = ({ sortBy, order }) => {
        fetch({ sort: { sortBy, order } }, true);
    };

    const reset = () => {
        return fetch({}, true);
    };

    return [object, isFetching, updateObject, { loadMore, onSort, onSearch, reset }];
};

export const useLazyLawPenalties = (newConfig = {}) => {
    const [isFetching, setIsFetching] = useState(true);

    const [object, setObject] = useState({
        lawPenalties: [],
        sort: {
            sortBy: "id",
            order: SORT_ORDER.ASC
        },
        totalCount: 0,
        cursor: "",
        search: "",
        selected: null,
        isAllSelected: false
    });

    const [load] = useLoadAllLawPenaltiesLazyMutation();

    const hasMore = object.totalCount > object.lawPenalties?.length || 0;

    const updateObject = (newObject = {}) => setObject({ ...object, ...newObject });

    const fetch = async ({ sort, ...config } = {}, isReset) => {
        if (!isFetching) {
            setIsFetching(true);
        }
        if (!sort) {
            sort = object.sort;
        }
        if (isReset) {
            config.cursor = "";
        }
        try {
            const response = await load({
                body: {
                    pageSize: DEFAULT_SIZE,
                    more: isReset ? DEFAULT_SIZE : LOAD_MORE_OFFSET,
                    ...object.sort,
                    ...sort,
                    ...(config || {})
                }
            });
            if (response.error) {
                throw new Error(response.error?.data?.message);
            }
            if (response.data && response.data.data) {
                const resdata = response.data.data;
                const temp = {
                    lawPenalties: isReset ? resdata.data : object.lawPenalties.concat(resdata.data),
                    cursor: resdata.cursor,
                    totalCount: resdata.totalCount,
                    isAllSelected: resdata.isAllSelected
                };
                sort && (temp.sort = sort);
                updateObject(temp);
            }
        } catch (error) {
            createToast(error.message || "Something went wrong with the server. Please contact support.", TOAST_TYPE.ERROR);
            sort && updateObject({ sort, lawPenalties: [], totalCount: 0 });
        } finally {
            setIsFetching(false);
        }
    };

    const loadMore = () => {
        hasMore && fetch({ cursor: object.cursor });
    };

    useEffect(() => {
        fetch(newConfig);
    }, []);

    const onSearch = (newInput) => {
        fetch({ search: newInput.trim().toLowerCase() }, true);
    };

    const onSort = ({ sortBy, order }) => {
        fetch({ sort: { sortBy, order } }, true);
    };

    const reset = () => {
        return fetch({}, true);
    };

    return [object, isFetching, updateObject, { loadMore, onSort, onSearch, reset }];
};

export const useLazySelectPenalties = ({ value = null, type, excludeIds = [], withoutShift, initializing, isFilter } = {}) => {
    const [isMounted, setMounted] = useState(false);
    const [object, setObject] = useState({
        data: [],
        sort: { sortBy: "title", order: "ASC" },
        totalCount: 0,
        cursor: "",
        search: ""
    });

    const hasMore = object.totalCount > object.data?.length || 0;

    const [load, { isLoading }] = useLoadAllCompanyPenaltyLazyMutation();

    const updateObject = (newObject = {}) => setObject((prev) => ({ ...prev, ...newObject }));

    const createRowItem = (row) => {
        const temp = cloneDeep(row || {});

        if (!temp.id) {
            return null;
        }

        const title = sanitizeWords(temp.title);
        temp.value = temp.id || "";
        temp.title = title || "";

        if (isFilter) {
            temp.label = (
                <div className="flex gap-05 align-center overflow-hidden small-font">
                    <Tooltip element="span" className="text-ellipsis semi-bold" message={title} style={{ maxWidth: "10rem" }}>
                        {title}
                    </Tooltip>
                </div>
            );
        } else {
            temp.label = (
                <div className="flex gap-05 align-center overflow-hidden" style={{ minHeight: "1.5rem" }}>
                    <Tooltip element="span" className="text-ellipsis semi-bold" message={title} style={{ paddingRight: "4px" }}>
                        {title}
                    </Tooltip>
                </div>
            );
        }

        for (const key in temp) {
            if (Object.prototype.hasOwnProperty.call(temp, key)) {
                const value = temp[key];
                if (isNull(value)) {
                    temp[key] = "";
                }
            }
        }
        return temp;
    };

    const fetch = async ({ sort, ...config } = {}, isReset) => {
        if (!sort) {
            sort = object.sort;
        }
        if (isReset) {
            config.cursor = "";
        }
        try {
            const response = await load({
                extraPath: !type ? ROUTE_TYPE.ALL : type,
                body: {
                    pageSize: DEFAULT_SIZE,
                    more: isReset ? DEFAULT_SIZE : LOAD_MORE_OFFSET,
                    excludeIds,
                    withoutShift,
                    ...object.sort,
                    ...sort,
                    ...(config || {})
                }
            });
            if (response.error) {
                throw new Error(response.error?.data?.message);
            }
            if (response.data && response.data.data) {
                let resdata = response.data.data || [];
                let newdata = [];
                if (isFilter) {
                    newdata = [...resdata.data].map(createRowItem);
                } else {
                    const resdata = response.data.data;
                    newdata = resdata.data.map(createRowItem);
                }

                const sameCursor = isEqual(object.cursor, resdata.cursor);
                const temp = {
                    data: isReset ? newdata : !sameCursor ? object.data.concat(newdata) : object.data,
                    cursor: resdata.cursor,
                    totalCount: resdata.totalCount
                };
                if (isFilter) {
                    temp.data = temp.data
                        .filter((t) => t.value)
                        .concat({
                            id: FILTER_ALL_VALUE.value,
                            value: FILTER_ALL_VALUE.value,
                            label: (
                                <div className="flex gap-05" style={{ alignItems: "center" }}>
                                    <span className="text-ellipsis small-font bold">All Penalties</span>
                                </div>
                            )
                        });
                }
                sort && (temp.sort = sort);
                updateObject(temp);
                return temp.data;
            }
        } catch (error) {
            createToast(error.message || "Something went wrong with the server. Please contact support.", TOAST_TYPE.ERROR);
            sort && updateObject({ sort, data: [], totalCount: 0 });
        }
    };

    const loadMore = () => hasMore && fetch({ cursor: object.cursor });
    const reset = () => fetch({}, true);
    const search = (value = "") => fetch({ search: value }, true);
    const sort = ({ sortBy, order }) => fetch({ sort: { sortBy, order } }, true);

    useEffect(() => {
        setMounted(true);
    }, []);

    useEffect(() => {
        if (isMounted && !initializing) {
            let val = value?.title;
            if (Array.isArray(value)) {
                val = [...value.map((v) => v.title)];
                val.sort((a, b) => a.localeCompare(b));
                val = val.pop();
            }
            fetch({ startFrom: val || "" });
        }
    }, [isMounted, initializing]);

    return [object, updateObject, { isLoading, hasMore, fetch, reset, loadMore, search, sort, createRowItem }];
};

export const useRemoveCompanyPenalty = () => {
    const [remove, { isLoading }] = useRemoveCompanyPenaltyMutation();

    const onRemove = async ({ id, title } = {}) => {
        if (!id && !title) return createToast("Failed to remove penalty", TOAST_TYPE.ERROR);
        const response = await remove({ extraPath: id });
        if (response.error) {
            return createToast(response.error?.data?.message, TOAST_TYPE.ERROR);
        } else {
            createToast("Penalty successfully deleted.", TOAST_TYPE.SUCCESS);
        }
    };

    return [onRemove, isLoading];
};

export const useSetCompanyPenaltiesWithPenalties = () => {
    const [set, { isLoading }] = useSetCompanyPenaltiesByPenaltyIdsMutation();

    const onSet = async (newPenalties = []) => {
        if (!newPenalties.length) return;
        const response = await set({
            body: {
                penalties: newPenalties
            }
        });
        if (response.error) {
            createToast(response.error?.data?.message, TOAST_TYPE.ERROR);
            return false;
        }
        createToast("Penalties successfully updated.", TOAST_TYPE.SUCCESS);
        return response;
    };

    return [onSet, isLoading];
};

export const useValidateFields = () => {
    const [validate, { isLoading }] = useValidateCompanyPenaltyFieldsMutation();

    const onValidate = async (penalty = {}) => {
        try {
            const response = await validate({ body: penalty });
            if (response.error) {
                throw new Error(response.error?.data?.message);
            }
            return true;
        } catch (error) {
            throw new Error(error);
        }
    };
    return [
        (penalty) =>
            createPromiseToast(() => onValidate(penalty), {
                render: {
                    pending: () => "Validating fields, please wait...",
                    success: () => "Fields are all valid",
                    error: (response) => {
                        return response?.data?.message || "Failed to validate fields.";
                    }
                }
            }),
        isLoading
    ];
};

export const useUpdateCompanyPenalty = () => {
    const [update, { isLoading }] = useUpdateCompanyPenaltyMutation();

    const onUpdate = async (id, updateObj = {}) => {
        try {
            if (!id) {
                throw new Error("Failed to update penalty.");
            }
            const response = await update({ extraPath: id, body: updateObj });
            if (response.error) {
                throw new Error(response.error?.data?.message);
            }
            createToast("Penalty successfully updated.", TOAST_TYPE.SUCCESS);
            return true;
        } catch (error) {
            createToast(error.message, TOAST_TYPE.ERROR);
            return false;
        }
    };

    return [onUpdate, isLoading];
};

export const useCreateCompanyPenalty = () => {
    const [create, { isLoading }] = useCreateCompanyPenaltyMutation();

    const onCreate = async (updateObj = {}) => {
        try {
            if (Array.isArray(updateObj.penalty_measures) && !updateObj.penalty_measures.length) {
                throw new Error("At least one penalty measure is required.");
            }
            const response = await create({ body: updateObj });
            if (response.error) {
                throw new Error(response.error?.data?.message);
            }
            createToast("Penalty successfully created.", TOAST_TYPE.SUCCESS);
            return true;
        } catch (error) {
            createToast(error.message, TOAST_TYPE.ERROR);
            return false;
        }
    };

    return [onCreate, isLoading];
};
