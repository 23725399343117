import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import { ReactComponent as PlusSVG } from "../../../assets/images/plus.svg";
import { ReactComponent as ArrowLeftSVG } from "../../../assets/images/arrow-left.svg";
import { ReactComponent as CloseSVG } from "../../../assets/images/close.svg";
import Loader from "./Loader";
import MyTooltip from "./Tooltip";

export const BTN_TYPE = {
    ADD: 0x1,
    BACK: 0x2,
    CLOSE: 0x3
};

const Button = forwardRef(function Button(
    {
        small,
        type = 0,
        onClick,
        className,
        children,
        options = {},
        transparent,
        disabled,
        isLoading,
        afterExtra,
        beforeExtra,
        mini,
        noLabel,
        tooltipProps
    },
    ref
) {
    const renderSwitch = () => {
        let render = "";
        let extraClass = "";
        switch (type) {
            case BTN_TYPE.ADD: {
                options.style = {
                    height: "100%"
                };
                render = (
                    <>
                        {!noLabel && <span>{children || "Add"}</span>}
                        <PlusSVG style={{ width: "1rem" }} />
                    </>
                );
                extraClass = `${transparent ? "transparent" : ""}`.trim();
                break;
            }
            case BTN_TYPE.BACK: {
                render = (
                    <>
                        <ArrowLeftSVG />
                        {!noLabel && <span>Back</span>}
                    </>
                );
                extraClass = "primary";
                break;
            }
            case BTN_TYPE.CLOSE: {
                render = <CloseSVG />;
                extraClass = "primary close-sa";
                break;
            }
            default: {
                render = children;
                extraClass = `${!small ? "large" : ""} ${transparent ? "transparent" : ""}`.trim();
            }
        }
        if (mini) {
            if (!options.style) options.style = {};
            options.style.height = "2rem";
        }
        const renderButton = (
            <button
                ref={ref}
                type="button"
                className={`tk-custom-btn ${extraClass || ""} ${className || ""}`.trim()}
                onClick={onClick}
                {...(options || {})}
                disabled={disabled || isLoading}
            >
                {beforeExtra}
                {render}
                {isLoading ? <Loader style={{ width: "max-content" }} relative /> : afterExtra}
            </button>
        );

        if (tooltipProps) {
            return <MyTooltip {...tooltipProps}>{renderButton}</MyTooltip>;
        }

        return renderButton;
    };
    return renderSwitch();
});

Button.propTypes = {
    small: PropTypes.bool,
    transparent: PropTypes.bool,
    className: PropTypes.string,
    type: PropTypes.oneOf([0, ...Object.values(BTN_TYPE)]),
    children: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.node]),
    beforeExtra: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.node]),
    afterExtra: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.node]),
    disabled: PropTypes.bool,
    isLoading: PropTypes.bool,
    onClick: PropTypes.func,
    options: PropTypes.object,
    mini: PropTypes.bool,
    noLabel: PropTypes.bool,
    tooltipProps: PropTypes.object
};

export default Button;
