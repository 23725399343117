import { useEffect, useRef, useState } from "react";
import cloneDeep from "lodash/cloneDeep";
import { createToast, TOAST_TYPE } from "../utilities/helper";
import { useAppDispatch, useAppSelector } from "./reduxHooks";

const usePaginateFetch = (hook, { defaultConfig, config = {}, redux = {}, runOnMount, onMountConfig, queryParams = {} }) => {
    const isMountedRef = useRef(false);

    const [loading, setLoading] = useState(true);

    const [func] = hook(config);

    const dispatch = useAppDispatch();

    const data = useAppSelector(redux.dataSelector);
    const tableConfig = useAppSelector(redux.tableConfigSelector);
    const current = useAppSelector(redux.currentSelector);

    const fetch = async (fetchConfig = {}, option = {}) => {
        let retval = null;
        if (!loading) {
            setLoading(true);
        }
        try {
            const body = cloneDeep({
                ...tableConfig,
                ...(fetchConfig || {}),
                filter: {
                    ...(tableConfig.filter || {}),
                    ...(fetchConfig.filter || {})
                }
            });
            let newConfig = body;
            // we need to reset table controls
            if (option.resetTable) {
                newConfig = cloneDeep(defaultConfig);
                newConfig.search = body.search || fetchConfig.search;
                newConfig.filter = { ...body.filter, ...fetchConfig.filter };
            }
            const response = await func({
                body: newConfig,
                ...queryParams,
                ...(option?.queryParams || {})
            });

            if (response.data && response.data.data) {
                const result = response.data.data;
                retval = {
                    data: result.data,
                    tableConfig: {
                        ...newConfig,
                        totalPage: result.totalPage,
                        totalCount: result.totalCount
                    }
                };
                dispatch(redux.setState(retval));
            }
            if (response.error) {
                throw new Error("Failed to fetch data Please try again later.");
            }
        } catch (error) {
            createToast(error.message, TOAST_TYPE.ERROR);
        } finally {
            setLoading(false);
        }
        return retval;
    };

    const handleFilter = (filter, extra = {}) => {
        return fetch({ filter: filter || {}, ...extra }, { resetTable: true });
    };

    const handleSearch = (value = "", extra = {}) => {
        return fetch({ search: (value || "").toLowerCase().trim(), ...extra }, { resetTable: true });
    };

    const handleResetTableConfig = (extra = {}) => {
        return fetch(extra, { resetTable: true });
    };

    const handleSort = ({ sortBy, order }, extra = {}) => {
        return fetch({ sortBy, order, ...extra }, { resetTable: true });
    };

    const handleUpdate = (id, newObject = {}) => {
        const newdata = data.map((record) => {
            if (record.id == id) {
                record = {
                    ...record,
                    ...(newObject || {})
                };
            }
            return record;
        });
        const temp = { data: newdata };
        if (current && current.id == newObject.id) {
            temp.current = { ...current, ...newObject };
        }
        dispatch(redux.setState(temp));
    };

    useEffect(() => {
        if (runOnMount) {
            if (isMountedRef.current) {
                if (!data.length) {
                    fetch(onMountConfig);
                } else {
                    setLoading(false);
                }
            }
        } else {
            setLoading(false);
        }
    }, [runOnMount, isMountedRef.current]);

    useEffect(() => {
        isMountedRef.current = true;
        return () => {
            isMountedRef.current = false;
        };
    }, []);

    return [
        fetch,
        loading,
        {
            onFilter: handleFilter,
            onSearch: handleSearch,
            onSort: handleSort,
            onUpdate: handleUpdate,
            resetTableConfig: handleResetTableConfig,
            setLoading,
            data,
            tableConfig
        }
    ];
};

export default usePaginateFetch;
