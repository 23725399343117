import React, { useEffect } from "react";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import isNumber from "lodash/isNumber";
import PropTypes from "prop-types";
import Input from "../../../../../common/components/extra/form/Input";
import { sanitizeWords, trimToLowerJoin } from "../../../../../common/utilities/helper";
import { useGetWorkSites } from "../../../companySites/hooks";

function MobileDropdown({ details, useValue }) {
    const [fetch, { data, isLoading }] = useGetWorkSites();

    // !TODO: Temporary fix just to show site for DROPDOWN but later on add a SITE FIELD that do the fetching and DROPDOWN should only be used for static data
    const toFetchSiteId = trimToLowerJoin(details.label).includes("site") && isNumber(details.value) && details.value;

    useEffect(() => {
        toFetchSiteId && fetch(toFetchSiteId);
    }, []);

    if (useValue) {
        return (
            <Input
                label={sanitizeWords(details.label)}
                renderValue={
                    toFetchSiteId && data?.result
                        ? data.result.title
                        : typeof details.value == "string"
                          ? sanitizeWords(details?.value)
                          : details.value?.label
                }
                parentStyle={{ gap: ".5rem" }}
                isLoading={isLoading}
                readOnly
                alignRight
            />
        );
    }

    return (
        <div className="mobile-input mobile-input--dropdown input-size">
            <div className="input-box flex center">
                <span className="fade italic">Dropdown</span>
            </div>
            <div className="dropdown-indicator">
                <ArrowDropDownIcon className="primary-color" style={{ width: "3rem", height: "auto" }} />
            </div>
        </div>
    );
}

export default MobileDropdown;

MobileDropdown.propTypes = {
    details: PropTypes.object,
    useValue: PropTypes.bool,
    renderAsMobile: PropTypes.bool
};
